import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import FloorPlans from "../components/FloorPlans";
import ProjectPageCarousel from "../components/ProjectPageCarousel/ProjectPageCarousel";
import AmenitiesSection from "../components/AmenitiesSection";
import ProjectGallery from "../components/ProjectGallery/ProjectGallery";
import OtherProjects from "../components/OtherProjects/OtherProjects";
import ProjectDescription from "../components/ProjectDescription";
import FloorPlansAzastha from "../components/FloorPlansAzastha";

const ProjectsPage = ({otherProjectsRef}) => {
  const { projectId } = useParams();

  useEffect(() => {
    console.log("Rendering ProjectsPage with projectId:", projectId);
    window.scrollTo(0, 0);
  }, [projectId]);

  return (
    <div>
      {projectId === "nakshatra-veda-i" && (
        <>
          <ProjectPageCarousel />
          <ProjectDescription />
          <AmenitiesSection />
          <ProjectGallery />
          <FloorPlans />
          <OtherProjects ref={otherProjectsRef} />
        </>
      )}
      {projectId === "nakshatra-aazstha" && (
        <>
          <ProjectPageCarousel />
          <ProjectDescription />
          <AmenitiesSection />
          <ProjectGallery />
          <FloorPlans />
          {/* <FloorPlansAzastha /> */}
          <OtherProjects ref={otherProjectsRef} />

        </>
      )}

      {projectId === "nakshatra-gokul" && (
        <>
          <ProjectPageCarousel />
          <ProjectDescription />
          <AmenitiesSection />
          <ProjectGallery />
          <FloorPlansAzastha />
          <OtherProjects ref={otherProjectsRef} />

        </>
      )}
      {projectId === "nakshatra-aarambh" && (
        <>
          <ProjectPageCarousel />
          <ProjectDescription />
          <AmenitiesSection />
          <ProjectGallery />
          <FloorPlans />
          {/* <FloorPlansAzastha /> */}
          <OtherProjects ref={otherProjectsRef} />

        </>
      )}
      {projectId === "nakshatra-prithvi" && (
        <>
          <ProjectPageCarousel />
          <ProjectDescription />
          <AmenitiesSection />
          <ProjectGallery />
          {/* <FloorPlansAzastha /> */}
          <FloorPlans />

          <OtherProjects ref={otherProjectsRef} />

        </>
      )}
      {projectId === "shree-ram-nagar" && (
        <>
          <ProjectPageCarousel />
          <ProjectDescription />
          <AmenitiesSection />
          <ProjectGallery />
          {/* <FloorPlansAzastha /> */}
          <FloorPlans />

          <OtherProjects ref={otherProjectsRef} />

        </>
      )}
      {projectId === "nakshatra-auris" && (
        <>
          <ProjectPageCarousel />
          <ProjectDescription />
          <AmenitiesSection />
          <ProjectGallery />
          {/* <FloorPlansAzastha /> */}
          <FloorPlans />

          <OtherProjects ref={otherProjectsRef} />
        </>
      )}
      {projectId !== "nakshatra-veda-i" &&
        projectId !== "nakshatra-aazstha" &&
        projectId !== "nakshatra-gokul" &&
        projectId !== "nakshatra-aarambh" &&
        projectId !== "nakshatra-prithvi" &&
        projectId !== "shree-ram-nagar" &&
        projectId !== "nakshatra-auris" && <div>Project not found.</div>}
    </div>
  );
};

export default ProjectsPage;
